import React, { useState } from "react"
import { graphql } from "gatsby"
import SidebarContent from "components/sidebarContent"
import { getUrlPath } from "utils/getUrlPath"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import VirtualSampleViewer from "components/VirtualSampleViewer"
import Logo from "../../../../static/RealCedar_simple-color.svg"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"

export default function VirtualSamplesTemplate(props) {
  const [selectedSample, setSelectedSample] = useState(null)
  const [open, setOpen] = useState(false)

  const {
    data: {
      wp: {
        themeSettings: {
          siteOptions: { sectionHeroImage }
        }
      },
      wpPage: {
        title,
        uri,
        templateVirtualSamples: { header, subHeader, virtualSamples }
      }
    },
    pageContext,
    uri: pathname
  } = props

  const getSectionImage = () => {
    if (!pageContext || !pageContext.translations) {
      return null
    }

    const defaultTranslation =
      process.env.GATSBY_ACTIVE_LANGUAGE === "EN"
        ? { uri: pathname.replace("/", "") }
        : pageContext.translations.find((o) => o.language.slug === "en")

    if (defaultTranslation) {
      const defaultPath =
        process.env.GATSBY_ACTIVE_LANGUAGE === "EN"
          ? defaultTranslation.uri.replace("en/", "").split("/")
          : defaultTranslation.uri.replace("/en/", "").split("/")

      if (defaultPath && defaultPath.length > 0) {
        const section = sectionHeroImage.find((o) => defaultPath[0].includes(o.path))

        if (section) {
          return section.image
        }
      }
    }

    return null
  }

  // ✅ Updated functions to control modal state properly
  const openModal = (sample) => {
    setSelectedSample(sample)
    setOpen(true)
  }

  const closeModal = () => {
    setSelectedSample(null)
    setOpen(false)
  }

  return (
    <div>
      <SidebarContent image={getSectionImage()} title={title} slug={getUrlPath(uri)} hideSidebarOnMobile>
        <div className="capitalize text-[24px] font-bold text-rc-grey mb-[15px]">{header || title}</div>
        <div className="bg-rc-light-grey h-[1px] w-full mb-[25px]"></div>

        <div className="text-[16px] text-rc-grey mb-[25px]">{subHeader}</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-[145px] gap-y-[30px]">
          {virtualSamples?.map((sample, index) => {
            const image = getImage(sample?.image?.localFile)
            if (!sample) {
              return null
            }
            return (
              <div
                key={index}
                onClick={() => openModal(sample)}
                className="flex flex-col items-center w-[235px] mb-[20px] mx-auto relative cursor-pointer"
              >
                {image && <GatsbyImage image={image} alt={sample.title} className="w-full h-[250px] mb-[5px]" />}
                {/* <div className="text-[18px] font-bold text-black my-[10px] text-center">{sample.title}</div> */}
                <div className="text-[18px] font-bold text-rc-grey my-[10px]">{sample.description}</div>
                <button className="bg-rc-light-grey px-[15px] py-3 font-semibold hover:bg-black hover:text-white">
                  VIEW VIRTUAL
                </button>
              </div>
            )
          })}
        </div>
      </SidebarContent>

      {selectedSample && (
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogContent className="max-w-4xl">
            <Logo className="w-[55px] h-fit absolute left-[15px] top-[15px]" />
            <DialogHeader>
              <div className="flex items-center justify-center relative md:mt-0 mt-[25px]">
                <DialogTitle>{selectedSample?.title}</DialogTitle>
              </div>
            </DialogHeader>
            {selectedSample && (
              <VirtualSampleViewer
                sample={selectedSample.virtualModel}
                selectedSample={selectedSample}
                openModal={openModal}
                closeModal={closeModal}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export const CollectionQuery = graphql`
  query VirtualSamplesTemplate($id: String!) {
    wp {
      themeSettings {
        siteOptions {
          sidebarOverride
          notifications {
            languageCode
            noteForMetrics
            noteForNorthAmericanStandards
          }
          sectionHeroImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
            }
            path
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      templateVirtualSamples {
        header
        subHeader
        virtualSamples {
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
          virtualModel {
            mediaItemUrl
          }
        }
      }
    }
  }
`
